
import {defineComponent, onMounted, computed} from "vue";
import { setCurrentPageBreadcrumbs, setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {MenuComponent} from "@/assets/ts/components";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import AppService from "@/core/services/AppService";
import {Actions} from "@/store/enums/StoreEnums";
const modelObject = 'project-categories';
export default defineComponent({
  name: modelObject + "-list",
  components: {VPagination},
  props: {
    page: {
      type: String,
      default: "1",
    },
    limit: {
      type: String,
      default: "5",
    },
    name: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "desc",
    },
    order_by: {
      type: String,
      default: "name",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    let params = {
      page: parseInt(props.page),
      limit: parseInt(props.limit),
      name: props.name,
      search: '{"type":"product"}',
      order: props.order,
      order_by: props.order_by,
    };
    const actionGetData = Actions.GET_ALL_PROJECT_CATEGORY;
    const objectName = 'ProjectCategory'
    const routerName = 'project-category'
    const limitData = [5, 10, 20, 50, 100];
    store.dispatch(actionGetData, AppService.convertParams(params));
    const listData = computed(() => store.getters["listData" + objectName]);
    const totalPages = computed(() => store.getters["getTotalPages" + objectName]);
    const totalDocs = computed(() => store.getters["getTotal" + objectName]);

    const onChangePage = async (next_page) => {
      params.page = next_page;
      await AppService.getDataListObject(
        params,
        store,
        router,
        actionGetData,
        routerName
      );
    };
    const onChangeLimit = async (event) => {
      let positionMax =
        params.limit * params.page > totalDocs.value
          ? totalDocs.value
          : params.limit * params.page;
      params.limit = event.target.value;
      params.page = Math.ceil(positionMax / params.limit);
      await AppService.getDataListObject(
        params,
        store,
        router,
        actionGetData,
        routerName
      );
    };
    const onClickSort = async (order_by) => {
      params.order_by = order_by;
      params.order = params.order === "desc" ? "asc" : "desc";
      params.search = '{"type":"product"}';
      await AppService.getDataListObject(
        params,
        store,
        router,
        actionGetData,
        routerName
      );
    };
    const onSearch = async () => {
      if(params.name != ""){
        let searchParams = {
          page: params.page,
          limit: params.limit,
          search:`{"name":"${params.name}","type":"product"}`,
          order: params.order,
          order_by: params.order_by,
        }
        await AppService.getDataListObject(
          searchParams,
          store,
          router,
          actionGetData,
          routerName
        );
      }
      else{
        await AppService.getDataListObject(
          params,
          store,
          router,
          actionGetData,
          routerName
        );
      }
    };
    const onClickDelete = (id) => {
      Swal.fire({
        text: "Bạn chắc chắn muốn xóa chứ ?",
        icon: "warning",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-primary",
        },
      }).then(function (result) {
        let searchString = AppService.convertParams(params);
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_PROJECT_CATEGORY, {id, searchString})
            .then(() => {
              Swal.fire({
                text: "Xóa thành công",
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getRoleErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Danh mục", ["Người dùng"]);
      setCurrentPageTitle("Danh mục");
      MenuComponent.reinitialization();
    });
    return {
      listData,
      params,
      limitData,
      totalDocs,
      modelObject,
      totalPages,
      onChangePage,
      onClickDelete,
      onChangeLimit,
      onClickSort,
      onSearch,
    };
  },
});
